import React, { FunctionComponent } from "react";
import Helmet from "react-helmet";
import Button from "react-bootstrap/Button";
import { event38 } from "../../analytics/event38";
import { WhereToBuyInterface } from "../WhereToBuy";

export interface BinCartWireWidgetInterface {
  binCartWire: any;
}

type BinCartWireButtonInterface = WhereToBuyInterface & BinCartWireWidgetInterface;

export const BinCartWireButton: FunctionComponent<BinCartWireButtonInterface> = ({
  binCartWire,
  productCode,
  productName,
  productCategory,
  brand,
  language,
  buyItNowLabel
}) => {
  return (
    <>
      {binCartWire?.length > 0 && productCode ? (
        <Button
          className="buy-button btn btn-primary"
          onClick={() => {
            //@ts-ignore
            loadsWidget(`${productCode}`, this, "retailPopup", `${language}`);
            event38(`${productCode}`, productName, productCategory, brand);
          }}
        >
          {buyItNowLabel || "Buy it now"}
        </Button>
      ) : null}
    </>
  );
};

const BinCartWireWidget: FunctionComponent<BinCartWireWidgetInterface> = ({ binCartWire }) => {
  return (
    <Helmet>
      <script async src={binCartWire}></script>
    </Helmet>
  );
};

export default BinCartWireWidget;

import React, { FunctionComponent, useContext } from "react";
import PriceSpiderWidget, { PriceSpiderButton } from "web-common/src/components/PriceSpider";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import BinCartWireWidget, { BinCartWireButton } from "web-common/src/components/BinCartWire";
import { LocalizedContext } from "../../services/LocalizedContextService";
import "./styles.scss";
import { MikmakButton } from "../Mikmak";

export interface WhereToBuyInterface {
  productCode: string | undefined;
  productName: string;
  productCategory: string;
  brand: string;
  buyItNowLabel?: string;
  language?: string;
}

export const WhereToBuyWidget: FunctionComponent = () => {
  const { language } = useContext(LocalizedContext);
  const { binCartWire, mikmakAppId, locale } = useSiteMetadata(language);

  return (
    <>
      {binCartWire?.length > 0 ? (
        <BinCartWireWidget binCartWire={binCartWire} />
      ) : !mikmakAppId ? (
        <PriceSpiderWidget language={language} locale={locale} />
      ) : null}
    </>
  );
};

export const WhereToBuyButton: FunctionComponent<WhereToBuyInterface> = ({
  productCode,
  productName,
  productCategory,
  brand
}) => {
  const { language } = useContext(LocalizedContext);
  const { binCartWire, mikmakAppId } = useSiteMetadata(language);
  const { buyItNow } = useContext(LocalizedContext).sanityLabels?.productLabels || {};

  return (
    <div className={"where-to-buy"}>
      {binCartWire?.length > 0 && productCode ? (
        <BinCartWireButton
          binCartWire={binCartWire}
          productCode={productCode}
          productName={productName}
          productCategory={productCategory}
          brand={brand}
          buyItNowLabel={buyItNow || "Buy it now"}
          language={language}
        />
      ) : mikmakAppId ? (
        <MikmakButton
          productCode={productCode}
          productName={productName}
          productCategory={productCategory}
          brand={brand}
        />
      ) : (
        <PriceSpiderButton
          productCode={productCode}
          productName={productName}
          productCategory={productCategory}
          brand={brand}
          buyItNowLabel={buyItNow || "Buy it now"}
          language={language}
        />
      )}
    </div>
  );
};

export default WhereToBuyWidget;

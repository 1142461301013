import React, { FunctionComponent } from "react";

interface BazaarvoiceRatingsInterface {
  upc: string;
  summary?: boolean;
}

const BazaarvoiceRatings: FunctionComponent<BazaarvoiceRatingsInterface> = props => {
  return (<React.Fragment>{
    props.summary ?
      (<div data-bv-show="rating_summary" data-bv-product-id={props.upc} className="bv-primary" />) :
      (<div data-bv-show="inline_rating" data-bv-product-id={props.upc} className="bv-primary" />)
  }</React.Fragment>);
}
export default BazaarvoiceRatings;

import React from "react";

export const ArrowRight = () => {
  return (
    <svg width="54px" height="54px" viewBox="0 0 74 74" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Arrow right</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1275.000000, -670.000000)">
          <g transform="translate(-2285.000000, 84.000000)">
            <g transform="translate(2306.000000, 586.000000)">
              <g transform="translate(1254.000000, 0.000000)">
                <path
                  d="M37,74 C57.4345357,74 74,57.4345357 74,37 C74,30.4282931 72.2867118,24.2567461 69.2824348,18.9076585 C62.944998,7.62391039 50.8628288,0 37,0 C16.5654643,0 0,16.5654643 0,37 C0,57.4345357 16.5654643,74 37,74 Z"
                  fill="#140041"
                />
                <path
                  d="M54,37.5325132 C54,37.9226714 53.6072696,38.3128295 53.2145391,38.3128295 L21.6182029,38.3128295 L37.9819709,54.56942 C38.2437912,54.8295255 38.2437912,55.3497364 37.9819709,55.7398946 C37.8510607,55.8699473 37.5892405,56 37.4583303,56 C37.3274202,56 37.0655999,55.8699473 36.9346897,55.7398946 L19.1309101,38.0527241 C19,37.9226714 19,37.9226714 19,37.7926186 C19,37.6625659 19,37.5325132 19,37.5325132 C19,37.4024605 19,37.2724077 19,37.2724077 C19,37.142355 19.1309101,37.142355 19.1309101,37.0123023 L37.0655999,19.1950791 C37.3274202,18.9349736 37.8510607,18.9349736 38.2437912,19.1950791 C38.5056115,19.4551845 38.5056115,19.9753954 38.2437912,20.3655536 L21.6182029,36.7521968 L53.2145391,36.7521968 C53.6072696,36.7521968 54,37.142355 54,37.5325132 Z"
                  stroke="#FFFFFF"
                  strokeWidth="3"
                  fill="#180044"
                  transform="translate(36.500000, 37.500000) rotate(-180.000000) translate(-36.500000, -37.500000) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

import React, { createRef, FunctionComponent, useContext, useEffect } from "react";
import Helmet from "react-helmet";
import Button from "react-bootstrap/Button";
import { WhereToBuyInterface } from "../WhereToBuy";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { event38 } from "../../analytics/event38";
import "./styles.scss";

type PriceSpiderInterface = WhereToBuyInterface;

interface PriceSpiderScriptInterface {
  language: string;
  locale: string;
}

export const PriceSpiderButton: FunctionComponent<PriceSpiderInterface> = ({
  productCode,
  productName,
  productCategory,
  brand,
  buyItNowLabel
}) => {
  useEffect(() => {
    if (typeof PriceSpider !== "undefined" && typeof PriceSpider.rebind !== "undefined") {
      PriceSpider.rebind();
    }
  }, []);

  const psButton = createRef<HTMLButtonElement>();

  const isPriceSpiderButtonVisible = () => {
    return psButton.current?.classList.contains("ps-enabled");
  };

  /**
   * The PriceSpider button does not propagate click events, so we cannot simply attach a click handler for analytics.
   * Therefore, we use another button to capture the user's click, track the analytics event, then click the PriceSpider
   * button.
   */
  const onClick = () => {
    const waitForPriceSpider = setInterval(() => {
      if (isPriceSpiderButtonVisible()) {
        if (typeof PriceSpider !== "undefined" && typeof PriceSpider.rebind !== "undefined") {
          PriceSpider.rebind();
        }
        psButton.current?.click();
        event38(productCode, productName, productCategory, brand);
        clearInterval(waitForPriceSpider);
      }
    }, 100);
  };

  return (
    <div className="priceSpider">
      <Button className="buy-it-now-button" onClick={onClick}>
        {buyItNowLabel}
      </Button>
      <button
        ref={psButton}
        aria-label="where to buy product search"
        className="ps-widget priceSpider-button"
        ps-sku={productCode}
      />
    </div>
  );
};

export const PriceSpiderWidget: FunctionComponent<PriceSpiderScriptInterface> = ({ language, locale }) => {
  const [lang, country] = locale.split("_");

  return (
    <Helmet>
      <meta name="ps-country" content={country} />
      <meta name="ps-language" content={language} />
      <script src="//cdn.pricespider.com/1/lib/ps-widget.js"></script>
    </Helmet>
  );
};

export default PriceSpiderWidget;

import React, { FunctionComponent, MouseEvent, useContext } from "react";
import { Link } from "../Link";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import Accessibility from "../Accessibility";
import { WhereToBuyButton } from "web-common/src/components/WhereToBuy";
import { Play } from "../../images/icons/play";
import { LocalizedContext } from "../../services/LocalizedContextService";
import ProductRatings from "../ProductRatings";
import "../SliderBlock/styles.scss";
import "./styles.scss";

import { PureVideoBlock as VideoBlock } from "../VideoBlockV2";

export interface VideoInterface {
  video: {
    asset: {
      url: string;
    };
  };
  coverImage: ImageInterface;
}
interface SlideInterface {
  id?: string;
  heading?: string;
  image?: ImageInterface | null;
  link?: string;
  itemPosition: string;
  type?: string;
  productColor?: string;
  textColor?: string;
  inView?: boolean;
  upc?: string;
  displayReviews?: boolean;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  subBrand?: string;
  brandName?: string;
  category?: string;
  showBuyItNowButton?: boolean;
  video?: VideoInterface | null;
  imageHeight?: number;
  imageWidth?: number;
}

const Slide: FunctionComponent<SlideInterface> = ({
  link,
  itemPosition,
  image = null,
  heading = "",
  type,
  productColor = "blue",
  textColor,
  inView,
  id = "",
  upc = "",
  displayReviews,
  showBuyItNowButton,
  subBrand,
  category = "",
  brandName,
  video = null,
  imageHeight,
  imageWidth,
  onClick = () => {} // eslint-disable-line @typescript-eslint/no-empty-function
}) => {
  const { readMore } = useContext(LocalizedContext).sanityAccessibility || {};
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const defineImageWidth = (defaultValue: number) => {
    return imageWidth || defaultValue;
  };
  const defineImageHeight = (defaultValue: number) => {
    return imageHeight || defaultValue;
  };

  const itemVideo = (
    <>
      {inView ? (
        <>
          {video && video.video?.asset && <VideoBlock data={video} imageHeight={imageHeight} imageWidth={imageWidth} />}
        </>
      ) : null}
    </>
  );

  const itemImage = (
    <>
      {image && (
        <>
          {inView ? (
            <picture>
              <source
                media={"(min-width: 1200px)"}
                srcSet={
                  urlBuilder
                    .image(image)
                    .auto("format")
                    .quality(80)
                    .width(defineImageWidth(386))
                    .height(defineImageHeight(386))
                    .format("webp")
                    .url() as string
                }
              />
              <img
                src={
                  urlBuilder
                    .image(image)
                    .auto("format")
                    .quality(80)
                    .width(defineImageWidth(365))
                    .height(defineImageHeight(365))
                    .format("webp")
                    .url() as string
                }
                alt={image.alt}
                className={"img-fluid image-position-" + itemPosition}
                style={{
                  backgroundImage: `linear-gradient(${productColor}, ${productColor}), linear-gradient(${productColor}, ${productColor})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "31px 75%, 50% 31px"
                }}
                data-testid={`rc-slide-${itemPosition}-${type}`}
              />
            </picture>
          ) : null}
        </>
      )}
    </>
  );

  const linkedItem = (
    <>
      {type === "playlist" ? (
        itemImage
      ) : (
        <div className={"image-container image-container-" + itemPosition}>{itemImage}</div>
      )}
      {type === "playlist" ? (
        <div className="play-svg img-fluid">
          <Play />
        </div>
      ) : (
        <hr />
      )}
      {heading && (
        <>
          {subBrand ? <div className="subtitle">{subBrand}</div> : null}
          <div className="title subhead">{type !== "image" && heading}</div>
          {type === "article" && (
            <div style={{ color: textColor }} className="readMore">
              <Accessibility location={readMore} />
            </div>
          )}
          {(type === "playlist" || type === "partners") && <Accessibility openNewWindow={true} />}
          {type === "product" && displayReviews && <ProductRatings productName={heading} upc={id} template="pdp" />}
          {type === "productV2" && displayReviews && <ProductRatings productName={heading} upc={upc} template="pdp" />}
        </>
      )}
    </>
  );

  const itemCTA = type === "productV2" && showBuyItNowButton && upc && (
    <WhereToBuyButton productCode={upc} productName={heading} productCategory={category} brand={brandName || ""} />
  );

  const renderLinkedSlide = () => {
    if (type === "article") {
      return (
        <Link
          className="card-container"
          _id={id}
          to={link as string}
          data-analytics-event63=""
          data-analytics-event4=""
        >
          {linkedItem}
        </Link>
      );
    } else if (type === "playlist" || type === "partners") {
      return (
        <a href={link} rel="noreferrer" className="card-container">
          {linkedItem}
        </a>
      );
    } else if (type === "productV2") {
      return (
        <div className="card-container">
          <Link _id={id} to={link as string} onClick={onClick}>
            {linkedItem}
          </Link>
          {itemCTA}
        </div>
      );
    }
    return (
      <Link className="card-container" _id={id} to={link as string} onClick={onClick}>
        {linkedItem}
      </Link>
    );
  };

  return (
    <>
      {link ? (
        renderLinkedSlide()
      ) : (
        <div className={"multiple-products image-container image-container-" + itemPosition}>
          {itemVideo ? itemVideo : itemImage}
        </div>
      )}
    </>
  );
};
export default Slide;

import { EventType, pushEvent } from "./index";

/**
 * Product Listing Click
 */
export const event12 = (
  productId: string,
  productName: string,
  productCategoryName: string,
  brand: string,
  listPosition: number,
  componentName: string,
  price?: string,
  quantity?: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;
  if (!win.digitalData.product) win.digitalData.product = [];
  if (!win.digitalData.component) win.digitalData.component = [];

  win.digitalData.product.push({
    productInfo: {
      productID: productId,
      productName,
      price,
      brand,
      quantity,
      componentName
    },
    category: {
      primaryCategory: productCategoryName
    },
    attributes: {
      productVariants: listPosition,
      listPosition
    }
  });

  pushEvent({
    eventInfo: {
      type: EventType.productclick
    },
    subcategory: EventType.interest
  });
};

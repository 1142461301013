import React, {FunctionComponent, useContext, useState, useRef, useEffect} from "react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import { Play } from "../../images/icons/play";
import { LocalizedContext } from "../../services/LocalizedContextService";
import RichTextImage from "../../components/RichTextImage";
// @ts-ignore
import { Player, ControlBar, ClosedCaptionButton } from "video-react";

// @ts-ignore
import {event41} from "../../analytics/event41";
import {event42} from "../../analytics/event42";
import {event54} from "../../analytics/event54";
import {event53} from "../../analytics/event53";
import {event52} from "../../analytics/event52";
import "./styles.scss";
import Accessibility from "../Accessibility";

export interface VideoInterface {
  video: {
    asset: {
      url: string;
    };
  };
  coverImage: ImageInterface;
}

export const PureVideoBlock: FunctionComponent<{
  data: VideoInterface;
  language?: string;
  imageWidth?: number;
  imageHeight?: number;
}> = ({ data, language, imageHeight, imageWidth }) => {
  const { sanityId, sanityDataset } = useSiteMetadata(language);
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });
  const [imageDisplay, setImageDisplay] = useState({ visibility: "visible" } as React.CSSProperties);
  const [videoPlaying, setVideoPlaying] = useState(false);

  const player = useRef(null);
  const { sanityAccessibility } = useContext(LocalizedContext);
  const { play } = sanityAccessibility || {};

  useEffect(() => {
    let previousPosition = 0;
    const title = data.coverImage.alt;
    const videoAssetId = data.video.asset.url;

    const onPlay = () => {
      event41(title, videoAssetId);
      // If the user pauses, we don't want to track subsequent play events
      (player?.current as any)?.video?.video?.removeEventListener("play", onPlay);
    };
    const onEnded = () => {
      event42(title, videoAssetId);
      // If the user watches the video again, track it
      (player?.current as any)?.video?.video?.addEventListener("play", onPlay);
    };
    const onTimeUpdate = () => {
      let currentPosition = 0;
      if ((player?.current as any)?.video?.video?.currentTime && (player?.current as any)?.video?.video?.duration) {
        currentPosition = (player?.current as any)?.video?.video?.currentTime / (player?.current as any)?.video?.video?.duration;
      }
      if (currentPosition >= 0.75 && previousPosition < 0.75) {
        event54(title, videoAssetId);
      }
      if (currentPosition >= 0.5 && previousPosition < 0.5) {
        event53(title, videoAssetId);
      }
      if (currentPosition >= 0.25 && previousPosition < 0.25) {
        event52(title, videoAssetId);
      }
      previousPosition = currentPosition;
    };

    (player?.current as any)?.video?.video?.addEventListener("play", onPlay);
    (player?.current as any)?.video?.video?.addEventListener("ended", onEnded);
    (player?.current as any)?.video?.video?.addEventListener("timeupdate", onTimeUpdate);

    return () => {
      (player?.current as any)?.video?.video?.removeEventListener("play", onPlay);
      (player?.current as any)?.video?.video?.removeEventListener("ended", onEnded);
      (player?.current as any)?.video?.video?.removeEventListener("timeupdate", onTimeUpdate);
    };
  });

  const toggleVideo = () => {
    setImageDisplay({ visibility: "hidden" });
    setVideoPlaying(!videoPlaying);
    (player as any)?.current.play();
  };

  const renderVideo = () => {
    return (
      <div className="square-video" hidden={!videoPlaying}>
        <Player ref={player}>
          <source src={data.video.asset.url} type="video/mp4" />
          <ControlBar autoHide={false}>
            <ClosedCaptionButton order={7} />
          </ControlBar>
        </Player>
      </div>
    );
  };

  const squareVideoAndImage = (
    <div className="video-container">
      <div onClick={() => toggleVideo()} className="square-cover-image" style={imageDisplay}>
        <RichTextImage data={data.coverImage} type="square-video-image" imageHeight={imageHeight} imageWidth={imageWidth} />
        <button className="square-play-icon">
          <Play />
          <Accessibility location={ play } />
        </button>
      </div>
      {renderVideo()}
    </div>
  );

  return squareVideoAndImage;
};

const VideoBlockV2: FunctionComponent<{data: VideoInterface}> = props => {
  const { language } = useContext(LocalizedContext);
  return <PureVideoBlock data={props as any} language={(language)} />;
};

export default VideoBlockV2;
